function queryTabbar() {
  return document.querySelectorAll<HTMLElement>('#tabbar-container');
}

let tabbarHeight = 0;
export function hideTabbar() {
  queryTabbar().forEach(i => {
    if (i.style.visibility === 'hidden') {
      return;
    }
    if (i.clientHeight != 0) {
      tabbarHeight = i.clientHeight;
    }
    i.style.height = '0';
    i.style.visibility = 'hidden';
    i.parentElement!.style.overflow = 'hidden';
  });
}

export function showTabbar() {
  queryTabbar().forEach(i => {
    if (!i.style.visibility || i.style.visibility == 'visible') {
      return;
    }
    i.style.height = `${tabbarHeight}px`;
    i.style.visibility = 'visible';
    i.parentElement!.style.overflow = 'auto';
  });
}
