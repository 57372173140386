import globalStore from '@/services/global.state';
import {goTo} from './route';
import {SafeAny} from '@/types';
import {getSideList} from '@/services/global.service';
import {getAdjustAttribution} from './adjust';
import DeviceInfo from 'react-native-device-info';
import {homePage} from '@/config';

export function needLoginBeforeDownload() {
  return (
    !globalStore.token &&
    (new URL(location.href).searchParams.get('pixelid') != null ||
      localStorage.getItem('pixelid') != null)
  );
}

export function stopBeforeDownload() {
  if (needLoginBeforeDownload()) {
    goTo('Login', {backPage: homePage});
    return true;
  }
  return false;
}

export function getImgs(sucCall: SafeAny, failCall?: SafeAny) {
  getSideList()
    .then(r => {
      sucCall(r);
    })
    .catch(e => {
      failCall && failCall(e);
      console.log('e', e);
    });
}
export function getDeviceCode() {
  if (globalStore.isWeb) {
    const adid = getAdjustAttribution()?.adid;
    if (adid) {
      localStorage.setItem('adjust_adid', adid);
      return adid;
    } else {
      let localAdid = localStorage.getItem('adjust_adid');
      if (!localAdid) {
        localAdid =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        localStorage.setItem('adjust_adid', localAdid);
      }
      return localAdid;
    }
  } else {
    return DeviceInfo.getAndroidId();
  }
}
