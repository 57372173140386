import {dicePayment, batchDicePayment} from '@/common-pages/dice/dice-service';
import {create, batchCreate} from '@/common-pages/color/color-service';
import {
  digitOrderCreate,
  crateQuickGame,
} from '@/common-pages/pick-digit/digit-service';
import {buyMatka} from '@/common-pages/satta/satta-service';
import {lottoBetting} from '@/common-pages/mix-lottery/mix-lottery-service';

export function diceOrder(data: any) {
  if (data.typeList) {
    return batchDicePayment(data.issNo, data.userId);
  }
  return dicePayment(data);
}

export function colorOrder(data: any) {
  if (data.subsetList) {
    return batchCreate(data.issueNo, data.userId);
  }
  return create(data);
}

export function pick3Order(data: any) {
  return digitOrderCreate(data);
}

export function quick3Order(data: any) {
  return crateQuickGame(data);
}

export function matkaOrder(data: any) {
  return buyMatka(data);
}

const baseLottoOrder = (data: any, isQuick = false) => {
  const {lotteryID, roundNo, ticketsLists} = data;
  return lottoBetting(
    ticketsLists
      ? {
          lotteryID,
          roundNo,
          tickets: ticketsLists.map((v: any) => {
            const {modeID, tabID, amount, fee, betItem} = v;
            return {
              modeID,
              tabID,
              amount: amount + fee,
              betItem,
            };
          }),
        }
      : data,
    isQuick,
  );
};

export function lottoOrder(data: any) {
  return baseLottoOrder(data);
}

export function quickLottoOrder(data: any) {
  return baseLottoOrder(data, true);
}
